import React, {useCallback, useRef} from 'react';
import {KeyboardArrowLeft, KeyboardArrowRight} from '@mui/icons-material';
import {registerGlobalStyle} from '../../theme';
import {Button} from '../Basic';
import classNames from 'classnames';
import {useLocalStorageState, useResizeObserver} from '../../hooks';
import {HoopsPropTypes} from '../utils';
import {Column, ExpansionButton, ExpansionPanel, ExpansionPanelProvider, Row} from '../Layout';
import {HeadingText} from '../Text';

registerGlobalStyle('.toolbox-drawer-spacer', (theme) => ({
  flexShrink: 1,
  flexGrow: 0,
  flexBasis: 'var(--toolbox-width)',
  transition: 'flex-basis .1s ease-in-out',
  '&.closed': {flexBasis: 0},
  '.toolbox-drawer': {
    position: 'fixed',
    width: 'var(--toolbox-width)',
    top: -1,
    bottom: -1,
    right: 0,
    transform: 'translateX(100%)',
    border: `1px solid ${theme.colors.border.main}`,
    borderRight: 'none',
    background: theme.colors.palette.white,
    transition: 'transform .1s ease-in-out, margin-right .1s linear',
    zIndex: theme.zIndex.toolbox,
    '&.open': {
      transform: 'translateX(0px)',
      transition: 'transform .1s ease-in-out, margin-right 0s linear',
    },
    '.toolbox-drawer-content': {
      width: '100%',
      height: '100%',
      overflowY: 'auto',
      overflowX: 'hidden',
      scrollbarWidth: 'thin',
      '&>div': {minHeight: '100%'},
    },
    '&>button.open-toggle': {
      position: 'absolute',
      left: 0,
      transform: 'translateX(-100%)',
      top: 280,
      padding: theme.spacing(1.875, 1.875),
      background: theme.colors.palette.white,
      borderColor: theme.colors.border.main,
      borderRight: 'none',
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      boxShadow: 'none',
      color: theme.colors.text.dark,
      transition: 'left 0s linear',
    },
    '&.closed>.open-toggle': {transition: 'left 0s .1s linear',},
    '.toolbox-section': {
      alignItems: 'stretch',
      borderTop: `1px solid ${theme.colors.border.main}`,
      paddingTop: theme.spacing(1.5),
      paddingInline: theme.spacing(1.5),
      margin: theme.spacing(3, 1.5, 0),
      flex: 'none',
      '&.inner-sep': {borderTopColor: theme.colors.border.lightest,},
      '&.no-sep': {
        marginTop: 0,
        borderTopColor: theme.colors.border.transparent,
        '&.no-heading': {
          paddingTop: 0,
          borderTopWidth: 0,
        }
      },
      '&.hidden + .toolbox-section:not(.no-sep)': {marginTop: theme.spacing(1.5)},
      '&.toolbox-heading + .toolbox-section': {
        marginTop: theme.spacing(1.5),
        marginInline: 0,
        paddingInline: theme.spacing(3),
      },
      '.toolbox-section': {
        marginInline: 0,
        paddingInline: 0,
        '.text-heading': {fontSize: '1.25rem'},
        '&:first-child': {
          paddingTop: 0,
          borderTopWidth: 0,
        }
      },
      '&.sticky-footer': {
        position: 'sticky',
        bottom: 0,
        backgroundColor: theme.colors.background.white,
        paddingInline: theme.spacing(3),
        marginInline: 0,
        zIndex: 1,
        '&::before': {
          content: '""',
          position: 'absolute',
          top: theme.spacing(-1.125),
          left: 0,
          width: '100%',
          height: theme.spacing(1),
          backgroundImage: 'linear-gradient(to bottom, #FFFFFF00, #FFFFFFFF)',
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: '100%',
          left: 0,
          width: '100%',
          height: theme.spacing(2),
          backgroundColor: theme.colors.background.white,
        },
      }
    },
    '.toolbox-section-fill': {flex: '1 1 0'},
    '.toolbox-heading': {
      'button.icon': {
        position: 'relative',
        top: 2,
      },
    },
    '.expand-button': {marginTop: 2,}
  },
}));

export function ToolboxDrawer({className, open, onOpenChange, onVisibleChange, width, children}) {
  const reserveRef = useRef();
  const scrollElem = reserveRef.current?.closest('.hoops-page > div');
  useResizeObserver(scrollElem);

  const handleOpenToggle = useCallback(() => {
    onOpenChange?.(!open);
  }, [onOpenChange, open]);

  const handleTransitionEnd = useCallback((e) => {
    if (onVisibleChange && e.target.classList.contains('toolbox-drawer')) {
      onVisibleChange(open);
    }
  }, [onVisibleChange, open]);

  const absorbClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const scrollOffset = scrollElem ? scrollElem.offsetWidth - scrollElem.clientWidth : 0;

  return (
    <div
      className={classNames([className, 'toolbox-drawer-spacer', open ? 'open' : 'closed'])}
      ref={reserveRef}
      style={{'--toolbox-width': `${width ?? 600}px`}}
      onTransitionEnd={handleTransitionEnd}
    >
      <div className={classNames(['toolbox-drawer', open ? 'open' : 'closed'])} style={{marginRight: open ? scrollOffset : 0}} onClick={absorbClick}>
        <Button
          unstyled
          className={'open-toggle'}
          style={{left: open ? 0 : -scrollOffset}}
          suffix={open ? KeyboardArrowRight : KeyboardArrowLeft}
          onClick={handleOpenToggle}
        />
        <div className='toolbox-drawer-content'>
          {children}
        </div>
      </div>
    </div>
  );
}

ToolboxDrawer.propTypes = {
  className: HoopsPropTypes.className,
  open: HoopsPropTypes.bool,
  onOpenChange: HoopsPropTypes.func,
  width: HoopsPropTypes.number,
  children: HoopsPropTypes.children,
};

export function ToolboxSection({className, heading, hideShow, hideText, innerSep, noSep, showText, stickyFooter, storageKey, children}) {
  const [showSection, setShowSection] = useLocalStorageState(storageKey, true);

  if (hideShow && !hideText) {
    hideText = 'Hide';
  }
  if (hideShow && !showText) {
    showText = 'Show';
  }

  className = [
    className,
    'toolbox-section',
    !heading && 'no-heading',
    (hideText || showText) && (showSection ? 'visible' : 'hidden'),
    innerSep && 'inner-sep',
    noSep && 'no-sep',
    stickyFooter && 'sticky-footer',
  ];

  return (
    <>
      {stickyFooter &&
        <Column className={'toolbox-section-fill'}/>
      }
      <Column className={className} fillWidth>
        {(hideText || showText) &&
          <ExpansionPanelProvider initShown={showSection} onChange={setShowSection}>
            <Row>
              {heading &&
                <HeadingText x20 normal heading={heading}/>
              }
              <ExpansionButton hideText={hideText} showText={showText} noChevron noWrap/>
            </Row>
            <ExpansionPanel>
              {children}
            </ExpansionPanel>
          </ExpansionPanelProvider>
        }
        {!hideText && !showText &&
          <>
            {heading &&
              <Row>
                {heading &&
                  <HeadingText x20 normal heading={heading}/>
                }
              </Row>
            }
            {children}
          </>
        }
      </Column>
    </>
  );
}

ToolboxSection.propTypes = {
  className: HoopsPropTypes.className,
  heading: HoopsPropTypes.stringOrBool,
  hideShow: HoopsPropTypes.bool,
  hideText: HoopsPropTypes.string,
  innerSep: HoopsPropTypes.bool,
  noSep: HoopsPropTypes.bool,
  showText: HoopsPropTypes.string,
  stickyFooter: HoopsPropTypes.bool,
  storageKey: HoopsPropTypes.string,
  children: HoopsPropTypes.children,
};

import React from 'react';
import {connect} from 'react-redux';
import {BrowserRouter, Route, Switch} from 'react-router-dom';

import {LicenseInfo} from '@mui/x-license-pro';
import {getCurrentUser} from '../actions/action-types';
import {getCompany} from '../actions/action-types/company';
import {GetUsers as _getUsers, SetUserData, UserLoggedIn} from '../actions/action-types/user';
import {AppReady} from '../actions/app';
import {getBilling, getUpcomingInvoice} from '../actions/billing';
import PublicWrapper from '../componentsOld/auth/login/PublicWrapper';
import IntercomLauncher from '../componentsOld/intercom/intercom';
import {TOKEN_KEY} from '../constants/api';
import User from '../core/auth';
import AppRoutes, {APP_BASENAME} from './routes/AppRoutes';
import GuestRoutes, {GUEST_BASENAME} from './routes/GuestRoutes';
import {asRoundedString} from '../utils';

// MUI X license key
LicenseInfo.setLicenseKey('8ebbc99b3b8fec472190af7c63e5aea6Tz05MzEyNyxFPTE3NTA5MTc4MzEwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

class App extends React.Component {
  init = () => {
    console.log('Current timezone offset', asRoundedString(new Date().getTimezoneOffset() / 60, 0, 1));

    let userToken = localStorage.getItem(TOKEN_KEY);
    if (userToken) {
      this.props.dispatch(getCurrentUser())
        .then(() => {
          this.props.dispatch(UserLoggedIn(true));
          this.props.dispatch(AppReady);

          // Tenant Information
          this.props.dispatch(getCompany());
          this.props.dispatch(_getUsers());

          // Billing
          this.props.dispatch(getBilling());
          this.props.dispatch(getUpcomingInvoice());
        });
    } else {
      this.props.dispatch(AppReady);
    }
  }

  login = (token) => {
    User.getUser(token)
      .then((data) => {
        if (data) {
          this.props.dispatch(SetUserData(data));
        } else {throw data;}
      })
      .then(() => {
        this.props.dispatch(UserLoggedIn(true));

        // Billing
        this.props.dispatch(getBilling());
        this.props.dispatch(getUpcomingInvoice());
      })
      .then(() => this.props.dispatch(AppReady))
      .catch(() => this.props.dispatch(AppReady));
  }

  componentDidMount() {
    this.init();
  }

  render() {
    const {auth} = this.props;

    return (
      !!this.props.appReady && (
        <BrowserRouter>
          <>
            <Switch>
              <Route path={GUEST_BASENAME}><GuestRoutes /></Route>
              {auth.isLoggedIn ?
                <>
                  <Route path={APP_BASENAME}>
                    <IntercomLauncher />
                    <AppRoutes />
                  </Route>
                </> :
                <>
                  <Route path={'/'}>
                    <IntercomLauncher />
                    <PublicWrapper />
                  </Route>
                </>
              }
            </Switch>

          </>
        </BrowserRouter>
      )
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.authReducer,
    appReady: state.appReducer.appReady
  };
}

export default connect(mapStateToProps)(App);

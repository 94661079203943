// TODO: fix eslint disable
/* eslint-disable no-unused-vars, no-shadow */

import {
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
  Typography
} from '@material-ui/core';
import React from 'react';
import {Controller, useFormContext} from 'react-hook-form';
import {useSelector} from 'react-redux';
import ControlledAutocomplete from './ControlledAutocomplete';
import {featureFlagEnabled, featureFlags} from '../../utils/featureFlag';
import {isEqual} from 'lodash';
import {useCompany} from '../../hooks';

const useStyles = makeStyles((theme) => ({
  greyText: {color: theme.colors.grey.main,},
  selectClass: {minWidth: 184,},
}));

export function AutomationAction({showLast, hasAction, viewData}) {
  const classes = useStyles();
  const {control, watch, setValue} = useFormContext();
  const entityType = watch('entityType');
  const statusType = watch('statusType');
  const trigger = watch('trigger');
  const via = watch('via');
  const recipients = watch('recipients');
  const action = watch('action');
  const company = useCompany();
  const users = useSelector((state) => state.authReducer.users) || [];
  const updateFieldPath = watch('updateFieldPath');

  let statusValues = [];
  if (updateFieldPath && company) {
    //list job statuses of selected job status type
    if (entityType === 'job' || entityType === 'salesdoc') {
      statusValues = (viewData?.fields
        ?.find(({path}) => path === updateFieldPath)
        ?.options
        ?.map(({statusLabel}) => ({value: statusLabel, label: statusLabel}))) || [];

      //list job statuses of selected job status type

    } else if (company.statuses) {
      const statuses = company
        .statuses
        .filter((status) => status.entityMapping === updateFieldPath)[0]
        .statuses;
      statusValues = statuses.map((status) => ({value: status.value, label: status.label}));
    }

  }

  const parseUsers = (users) => {
    const parsedUsers = [];
    users.filter(({status}) => status !== 'INACTIVE').forEach((user) => {
      parsedUsers.push(
        {
          _id: user._id,
          label: user.fullName,
          type: 'user',
        }
      );
    });
    return (parsedUsers);
  };

  // construct the custom items at the top of the list eg 'job owner' etc
  const options = [];

  if (via === 'email') {
    options.push({_id: 'customer', label: 'The customer', type: 'customer',});
    entityType === 'quote' && options.push({_id: 'owner', label: 'The owner', type: 'owner',});
    entityType === 'job' && options.push({_id: 'owner', label: 'Job owner', type: 'jobOwner',});
    options.push({_id: 'createdBy', label: 'Created by', type: 'createdBy',});
    if (users) {
      options.push(...parseUsers(users));
    }
  } else if (via === 'sms') {
    options.push({_id: 'customer', label: 'The customer', type: 'customer',});
    // clear existing selection if it's not "the customer"
    const customerIsSelected = recipients.findIndex((el) => el._id === 'customer') > -1;
    const customerObject = {
      '_id': 'customer',
      'type': 'customer',
      'label': 'The customer'
    };
    if (!isEqual(customerIsSelected ? [customerObject] : [], recipients)) {
      setValue('recipients', customerIsSelected ? [customerObject] : []);
    }
  }

  let statusFields = [];
  if (entityType && company) {
    if (entityType === 'job' || entityType === 'salesdoc') {
      statusFields = (viewData?.fields
        ?.filter(({type, path}) => type === 'STATUS' && (trigger !== 'statusChanged' || path !== statusType))
        ?.map(({path, title}) => ({value: path, label: title}))) || [];
    } else if (company.statuses) {
      //list job status types
      const jobStatusType = company
        .statuses
        .filter((status) => status.entityType === entityType);
      statusFields = jobStatusType.map((status) => ({value: status.entityMapping, label: status.label}));
    }
  }

  return (
    <Grid container direction='row' justifyContent='center' alignItems='flex-start' spacing={2}>
      <Grid item>
        <FormControl>
          <InputLabel id='action-label'>What we should do?</InputLabel>
          <Controller
            render={({onBlur, onChange, value}) =>
              <Select
                inputProps={{className: classes.selectClass}}
                onBlur={onBlur}
                onChange={onChange}
                value={value ? value : ''}
                labelId='action-label'
              >
                {(entityType !== 'job' || !['proofs', 'purchaseOrder', 'accountingStatus'].includes(trigger)) &&
                  <MenuItem value='notify'>Notify</MenuItem>}
                {entityType === 'job' && ['proofs', 'purchaseOrder', 'accountingStatus', 'statusChanged'].includes(trigger) &&
                  <MenuItem value='updateField'>Update Job Status</MenuItem>}
                {entityType === 'salesdoc' &&
                  <MenuItem value='updateField'>Update Document Status</MenuItem>}
              </Select>
            }
            name='action'
            control={control}
          />
        </FormControl>
      </Grid>
      {hasAction &&
        <>
          <Grid item>
            {action === 'updateField' ? <FormControl>
                <InputLabel id='action-label'>Choose Status Column</InputLabel>
                <Controller
                  render={({onBlur, onChange, value}) =>
                    <Select
                      inputProps={{className: classes.selectClass}}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ? value : ''}
                      labelId='action-label'
                    >
                      {statusFields.map((option, optIdx) =>
                        <MenuItem key={optIdx} value={option.value}>{option.label}</MenuItem>
                      )}
                    </Select>
                  }
                  name='updateFieldPath'
                  control={control}
                />
              </FormControl>
              :
              <FormControl>
                <InputLabel id='action-label'>Via</InputLabel>
                <Controller
                  render={({onBlur, onChange, value}) =>
                    <Select
                      inputProps={{className: classes.selectClass}}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ? value : ''}
                      labelId='action-label'
                    >
                      <MenuItem value='email'>Email</MenuItem>
                      {featureFlagEnabled(featureFlags.sms_notifications) && <MenuItem value='sms'>SMS</MenuItem>}
                    </Select>
                  }
                  name='via'
                  control={control}
                />
              </FormControl>}
          </Grid>
        </>
      }
      {showLast &&
        <>
          {action === 'updateField' && <Grid item>
            <Typography variant='h5' className={classes.greyText}> to </Typography>
          </Grid>}
          <Grid item>
            {action === 'updateField' ? <FormControl>
                <InputLabel id='action-label'>Choose Status</InputLabel>
                <Controller
                  render={({onBlur, onChange, value}) =>
                    <Select
                      inputProps={{className: classes.selectClass}}
                      onBlur={onBlur}
                      onChange={onChange}
                      value={value ? value : ''}
                      labelId='action-label'
                    >
                      {statusValues.map((option, optIdx) =>
                        <MenuItem key={optIdx} value={option.value}>{option.label}</MenuItem>
                      )}
                    </Select>
                  }
                  name='updateFieldValue'
                  control={control}
                />
              </FormControl>
              :
              <ControlledAutocomplete
                name='recipients'
                options={options}
                multiple={true}
                disableCloseOnSelect={true}
                getOptionLabel={(option) => option.label}
                getOptionSelected={(option, value) => option._id === value._id}
                renderOption={(option, {selected}) => (
                  <>
                    <Checkbox
                      color={'primary'}
                      style={{marginRight: 8}}
                      checked={selected}
                    />
                    {option.label}
                  </>
                )}
                renderInput={(params) => (
                  <TextField {...params} label='Who should be notified?'/>
                )}
                control={control}
              />}
          </Grid>
        </>
      }
    </Grid>
  );
}

// TODO: fix eslint disable
/* eslint-disable no-shadow, no-unused-vars, */

import DateFnsUtils from '@date-io/date-fns';
import {FormControl, FormControlLabel, Input, InputLabel, MuiThemeProvider, OutlinedInput, Select, TextField} from '@material-ui/core';
import Chip from '@material-ui/core/Chip';
import {blue} from '@material-ui/core/colors';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Typography from '@material-ui/core/Typography';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {KeyboardDatePicker, MuiPickersUtilsProvider} from '@material-ui/pickers';
import {findIndex, pick} from 'lodash';
import ChipInput from 'material-ui-chip-input';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import dayjs from 'dayjs';
import green from '../../../ui/theme/green';
import theme from '../../../ui/theme/index';
import {decimalPlaces} from '../numberUtils';
import {asRoundedString} from '../../../utils';

export const HoopsDatePicker = ({
  input,
  label,
  inputVariant,
  ...InputAdornmentProps
}) => (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        {...input}
        variant='dialog'
        inputVariant={inputVariant}
        clearable={true}
        format='dd MMM yyyy'
        margin='normal'
        label={label}
        style={{margin: '0px'}}
        onChange={(value) => input.onChange(value ? value.getTime() : null)}
        value={input.value ? new Date(input.value) : null}
        KeyboardButtonProps={{'aria-label': 'change date',}}
        {...InputAdornmentProps}
      />
    </MuiPickersUtilsProvider>
  );

export const UserAutocompleteField = ({
  label,
  input,
  ...custom
}) => {
  const usersSelector = (state) => state.authReducer.users;
  const users = useSelector(usersSelector);
  const value = !input?.value
    ? null
    : users.find((user) => user._id === input.value) || null;

  return (
    <Autocomplete
      label={label}
      options={users}
      placeholder={label}
      getOptionLabel={(option) => option.fullName}
      getOptionSelected={(option, selected) => option._id === selected}
      value={value}
      {...custom}
      onChange={(event, value) => input.onChange(value ? value._id : null)}
      renderInput={(params) => (
        <TextField {...params} label={label} variant='outlined' fullWidth />
      )}
    />
  );
};

export const renderSelectField = (props) => {
  const {
    fullWidth,
    variant,
    label,
    input,
    InputProps,
    touched,
    error,
    children,
    inputLabelProps,
    ...custom
  } = props;
  return (
    <FormControl fullWidth variant={variant}>
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      <Select
        input={
          variant === 'outlined' ? (
            <OutlinedInput {...input} {...InputProps} />
          ) : (
            <Input {...input} {...InputProps} />
          )
        }
        error={Boolean(touched && error)}
        {...custom}
      >
        {children}
      </Select>
    </FormControl>
  );
};

export const renderLabelUpSelectField = ({
  input,
  label,
  meta: {touched, error},
  children,
  ...custom
}) => (
  <FormControl {...custom}>
    <InputLabel shrink>{label}</InputLabel>
    <Select
      input={<Input {...input} />}
      error={Boolean(touched && error)}
      {...custom}
    >
      {children}
    </Select>
  </FormControl>
);

export const renderInputField = ({
  input,
  label,
  meta: {error},
  inputLabelProps,
  uploadState,
  ...custom
}) => {
  const formControlProps = pick(custom, [
    'fullWidth',
    'variant',
    'margin',
    'required',
    'error',
    'disabled',
    'classes',
  ]);
  if (custom.type === 'file') {
    delete input.value;
  }

  return (
    <FormControl {...formControlProps} error={Boolean(error)}>
      <InputLabel {...inputLabelProps}>{label}</InputLabel>
      <Input error={Boolean(error)} {...input} {...custom} />
    </FormControl>
  );
};

export const renderTextField = ({
  input,
  label,
  meta: {error},
  size,
  isBold,
  decimals = null,
  maxDecimals = null,
  ...custom
}) => {
  if (decimals && (input?.value ?? input?.defaultValue)) {
    const value = input?.value ?? input?.defaultValue ?? 0;
    const withDecimals = decimalPlaces(value, decimals);
    if (withDecimals !== input.value) {
      input = {...input, defaultValue: withDecimals, value: undefined};
    }
  }
  if (maxDecimals && (input?.value ?? input?.defaultValue)) {
    const value = input?.value ?? input?.defaultValue ?? 0;
    input = {...input, defaultValue: asRoundedString(value, 0, maxDecimals), value: undefined};
  }
  return(
    <Fragment>
      <TextField
        label={label}
        fullWidth
        InputProps={{style: {fontWeight: isBold ? 'bold' : undefined,},}}
        error={Boolean(error)}
        helperText={error ? error : null}
        size={size}
        {...input}
        {...custom}
        onChange={(e) => {
          if (decimals) {
            const match = e.target.value.match?.(/([0-9]*\.[0-9][0-9])(.*)/);
            if (match && match[2] && e.target.value !== match[1]) {
              e.target.value = match[1];
            }
          }
          input?.onChange?.(e);
          custom?.onChange?.(e);
        }}

      />
    </Fragment>
  );};

export const FileTextField = ({fullWidth, input, label, ...custom}) => {
  delete input.value;
  return (
    <TextField
      label={label}
      fullWidth={fullWidth}
      type='file'
      {...input}
      {...custom}
    />
  );
};

export const datePickerFieldMidday = (props) => datePickerField({...props, midday: true});

export const datePickerField = (props) => {
  const {
    meta: {submitting, error, touched},
    input: {onBlur, value, ...inputProps},
    ...others
  } = props;

  const onChange = (date) => {
    let value = null;
    if (Date.parse(date)) {
      value = props.midday ? dayjs(date).format('YYYY-MM-DD[T10:00:00Z]') : date.toISOString();
    }
    inputProps.onChange(value);
  };

  const pickerDefaults = {
    variant: 'dialog',
    clearable: true,
    inputVariant: 'outlined',
  };

  const fieldDefaults = {
    fullWidth: true,
    variant: 'outlined',
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        {...fieldDefaults}
        {...pickerDefaults}
        {...inputProps}
        {...others}
        format='dd MMM yyyy'
        value={value ? new Date(value) : null}
        disabled={submitting || props.disabled}
        InputAdornmentProps={{position: 'start'}}
        onBlur={() => onBlur(value ? new Date(value).toISOString() : null)}
        error={error && touched}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>
  );
};

export const readOnlyAddress = (props) => {
  const {
    input: {value},
    defaultValues,
  } = props;
  const address = value || defaultValues;

  let addressString = [
    'address1', 'address2', 'city', 'state', 'postcode', 'country'
  ]
    .filter((key) => Boolean(address && address[key]))
    .map((key) => address[key])
    .join(', ');

  return (
    <>
      {(address ?
        <Typography variant='body2' gutterBottom>
          {addressString}
        </Typography> :
        <Typography variant={'body2'} color={'textSecondary'} gutterBottom>No linked address</Typography>)}
    </>
  );

};

export const timePickerField = ({
  inputProps,
  input,
}) => (
    <TextField
      id='time'
      type='time'
      onChange={(event) => inputProps.onTimeChange(event.target.value)}
      InputLabelProps={{shrink: true,}}
      inputProps={{step: 300,}} // 5 min
      {...input}
    />
  );

export const RadioGroupField = ({
  label,
  selections,
  input,
}) => {
  const defaultControl = <Radio color={'primary'} />;
  const {value} = input;
  return (
    <FormControl>
      {label && <FormLabel>{label}</FormLabel>}
      <RadioGroup row value={value} {...input}>
        {selections.map((selection, index) => (
          <FormControlLabel
            key={index}
            disabled={selection.disabled}
            value={selection.value}
            control={defaultControl}
            label={selection.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

const ChipRenderer = (
  {
    value,
    text,
    isFocused,
    isDisabled,
    isReadOnly,
    handleClick,
    handleDelete,
    className,
  },
  key
) => (
  <Chip
    key={key}
    className={className}
    style={{
      pointerEvents: isDisabled || isReadOnly ? 'none' : undefined,
      backgroundColor: isFocused ? blue[300] : undefined,
    }}
    onClick={handleClick}
    onDelete={handleDelete}
    label={text.name}
  />
);

export function RenderChipInput({
  label,
  input: {onChange, value},
  meta: {error},
  fullWidth,
  ...custom
}) {
  const handleDeleteChip = useCallback(
    (numberOfChips, index) => {
      value.splice(index, 1);
      setTimeout(onChange(value));
    },
    [onChange, value]
  );

  const handleAddChip = useCallback(
    (chip) => {
      onChange([...value, {name: chip}]);
    },
    [onChange, value]
  );

  return (
    <>
      <ChipInput
        {...custom}
        value={value}
        onAdd={handleAddChip}
        onDelete={handleDeleteChip}
        label={label}
        FormHelperTextProps={{error: Boolean(error),}}
        helperText={error ? error : null}
        fullWidth={fullWidth}
        newChipKeyCodes={[13, 9]}
        newChipKeys={['Enter', 'Tab']}
        chipRenderer={ChipRenderer}
      />
      <pre>{JSON.stringify(value, null, 2)}</pre>
    </>
  );
}

export const MultipleAutocompleteField = ({
  fullWidth,
  input: {name, value, onChange, onBlur, ...restInput},
  meta: {touched, error},
  label,
  options,
  freeSolo,
  helperText: customHelperText,
  primaryKey = '_id',
  ...rest
}) => {
  const [textValue, setTextValue] = useState('');
  const [helperText, setHelperText] = useState(customHelperText);

  const addOption = useCallback(
    () => {
      if (!textValue.length) {
        return;
      }
      let index = findIndex(options, (o) => o.name.toUpperCase() === textValue.toUpperCase());
      if (index > -1) {
        value.push(options[index]);
        onChange(value);
      } else if (freeSolo) {
        value.push({name: textValue});
        onChange(value);
      }
      setTextValue('');
    },
    [onChange, options, textValue, value, freeSolo]
  );

  const handleChange = useCallback(
    (e, val) => {
      onChange(val);
    },
    [onChange]
  );

  const handleBlur = useCallback(
    (e) => {
      addOption(e);
      onBlur(e);
    },
    [addOption, onBlur]
  );

  const handleKeyPress = useCallback(
    (e) => {
      if (['Tab', 'Enter'].includes(e.key) && textValue.length && freeSolo) {
        e.stopPropagation();
        e.preventDefault();
        addOption(e);
      }
    },
    [addOption, textValue.length, freeSolo]
  );

  useEffect(() => {
    if (error && value.length <= 0) {
      if (!customHelperText) {
        setHelperText(error);
        return;
      }
      setHelperText(`${customHelperText} | ${error}`);
    }
  }, [touched, error, customHelperText, value.length]);

  return (
    <Autocomplete
      {...rest}
      {...restInput}
      options={options}
      freeSolo={freeSolo}
      autoHighlight
      multiple
      id={name}
      value={value}
      inputValue={textValue}
      onChange={handleChange}
      onBlur={handleBlur}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => {
          const color = option[primaryKey] ? theme : green;
          return (
            <MuiThemeProvider key={index} theme={color}>
              <Chip
                variant={'outlined'}
                label={option.name}
                color={'primary'}
                {...getTagProps({index})}
              />
            </MuiThemeProvider>
          );
        })
      }
      getOptionSelected={(value, option) => value[primaryKey] === option[primaryKey]}
      getOptionLabel={(option) => option.name}
      renderOption={(option) => (
        <>
          <Typography>{option.name}</Typography>
        </>
      )}
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            label={label}
            fullWidth={fullWidth}
            value={textValue}
            onKeyDown={handleKeyPress}
            onBlur={onBlur}
            onChange={(e) => setTextValue(e.target.value)}
            error={Boolean(error) && value.length <= 0}
            helperText={helperText}
          />
        </>
      )}
    />
  );
};

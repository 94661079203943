import * as React from 'react';
import theme from '../../../../ui/theme';
import {useCallback, useContext, useState} from 'react';
import {formatDateLong, formatTime} from '../../../../utils';
import {CellContainer} from './CellContainer';
import {DatePicker} from '../../../../componentsLib/Pickers';
import {Placement} from '../../../../componentsLib/Popovers';
import {PageContext} from '../../../../componentsOld/PageContext';

const dateColor = (purpose, dueDate) => {
  switch (purpose) {
    case 'dueDate': {
      const now = new Date();
      let daysToDueDate = Math.ceil((now.getTime() - dueDate.getTime()) / (1000 * 3600 * 24));
      if (daysToDueDate > -7 && daysToDueDate < 0) {
        return theme.colors.orange;
      }
      if (daysToDueDate > 0) {
        return theme.colors.red;
      }
      break;
    }

    default:
      break;
  }
  return theme.palette.text.primary;
};

export const DateCell = ({value, colDef, ...props}) => {
  const {updateField} = useContext(PageContext);
  const [open, setOpen] = useState(props.cellMode === 'edit');
  const date = new Date(value);

  const handleClick = useCallback((event) => {
    event.stopPropagation();
    if (colDef.editable) {
      setOpen((isOpen) => !isOpen);
    }
  }, [colDef.editable]);

  const handleChange = useCallback((newValue) => {
    if (newValue !== value) {
      if (updateField) {
        updateField({_id: props.row._id, field: props.field, value: newValue});
      }
    }
    setOpen(false);
  }, [value, props.row._id, props.field, updateField]);

  const handleClose = useCallback((event) => {
    event?.stopPropagation();
    setOpen(false);
  }, [setOpen]);

  return (
    <CellContainer className={'DateCell-Container'} onClick={handleClick}>
      <>
        <div className={'DateCell'} style={{color: dateColor(colDef.purpose, date)}}>
          <span>{value ? `${formatDateLong(date)}${colDef.showTime ? ' ' + formatTime(date) : ''}` : ''}</span>
        </div>
        {open &&
          <DatePicker initialOpen={true} placement={Placement.Bottom} value={value} onChange={handleChange} onClose={handleClose} />
        }
      </>
    </CellContainer>
  );
};

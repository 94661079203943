import * as React from 'react';
import {useParams} from 'react-router';
import TabPanel from '@mui/lab/TabPanel';
import TabContext from '@mui/lab/TabContext';
import {Grid} from '@mui/material';
import {useCallback, useEffect, useMemo} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {HoopsTabs} from './HoopsTabs';
import Comments from '../../componentsOld/Comments/Comments';
import JobProofs from '../../componentsOld/jobs/SingleJob/JobProofs/JobProofs';
import {JobPurchaseOrders} from '../../componentsOld/jobs/SingleJob/JobPurchaseOrders';
import {JobShipping} from '../../componentsOld/jobs/SingleJob/JobShipping';
import JobTasks from '../../componentsOld/jobs/SingleJob/JobTasks';
import Files from '../../modules/file/Files';
import {JobHeading} from '../JobsPage/JobHeading';
import {JobDetails} from './tabs/JobDetails';
import {getJob} from '../../actions/jobs';
import {permissionCheck, SubscriptionTypes} from '../../componentsHoops';
import {useGetJob, useUpdateJob} from '../../hooks/api';
import {JobActivityTab} from './tabs/JobActivityTab';
import {JobCredits} from './tabs/JobCredits';
import {JobStatusBar} from './JobStatusBar';

export const JobPage = ({job: initialJobFromJobBoard = null, updateJobBoard}) => {
  const dispatch = useDispatch();
  const {_id: jobId} = useParams();
  const {data: jobFull, refetch} = useGetJob(initialJobFromJobBoard?._id || jobId, {});
  const jobSelector = (state) => state.jobsReducer.activeJob;
  const activeJob = useSelector(jobSelector);

  // When mounted, the job is passed from job board, but it does not have all of the data in the form expected by
  // the old job page. The old job page can also be loaded by other means so we can't just use the new form.
  // When mounted we will use the new format initially, while the full job is loaded using the old API. Once the
  // new job is loaded we will use it.
  const job = useMemo(() => ({
    ...initialJobFromJobBoard,
    ...activeJob,
    voided: jobFull?.voided,
    completed: jobFull?.completed,
    departmentalStatuses: jobFull?.departmentalStatuses ?? initialJobFromJobBoard?.departmentalStatuses,
    viewFields: jobFull?.viewFields ?? initialJobFromJobBoard?.viewFields
  }), [initialJobFromJobBoard, activeJob, jobFull]);

  const {mutateAsync: updateJob} = useUpdateJob();
  const [selectedTab, setSelectedTab] = React.useState('details');
  const companySelector = (state) => state.companyReducer.company;
  const company = useSelector(companySelector);

  useEffect(() => {
    // This condition runs when loaded from the JobBoard
    if (initialJobFromJobBoard?._id) {
      dispatch(getJob(initialJobFromJobBoard?._id));
    }

    // When this page isn't loaded from the JobBoard load the job
    if (jobId && !initialJobFromJobBoard?._id) {
      dispatch(getJob(jobId));
    }
  }, [initialJobFromJobBoard?._id, jobId, dispatch, job.viewFields, job.departmentalStatuses]);

  const handleTabChange = (e, tab) => {
    setSelectedTab(tab);
  };

  const refetchJob = useCallback(async() => {
    // When status or archived has been changed getJob retrieves the job with the updated data
    dispatch(getJob(initialJobFromJobBoard?._id || jobId));
    await refetch();
    await updateJobBoard();
  }, [initialJobFromJobBoard, dispatch, jobId, refetch, updateJobBoard]);

  const onStatusSelect = useCallback(async (selected, field) => {
    await updateJob({
      id: job._id,
      data: {[field]: selected.statusLabel}
    });
    await refetchJob();
  }, [job, updateJob, refetchJob]);

  return (
    <TabContext value={selectedTab}>
      <Grid container>
        <Grid item xs={12}>
          <JobHeading data-intercom-target={'job-heading'} job={job} updateJobBoard={refetchJob} company={company} />
        </Grid>
        <JobStatusBar job={job} onSelect={onStatusSelect} />
        <Grid item xs={12}>
          <HoopsTabs
            value={selectedTab}
            onChange={handleTabChange}
            items={
              [
                {label: 'Job Detail', value: 'details'},
                ...permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) ? [{label: 'Purchase Orders', value: 'purchase-orders'}] : [],
                {label: 'Proofs', value: 'proofs'},
                ...permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) ? [{label: 'Files', value: 'files'}] : [],
                {label: 'Comments', value: 'comments'},
                ...permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) ? [{label: 'Tasks', value: 'tasks'}] : [],
                {label: 'Activity', value: 'activity'},
                ...permissionCheck({disallowedSubscriptions: [SubscriptionTypes.proofs]}) ? [{label: 'Shipping', value: 'shipping'}] : [],
                ...permissionCheck({allowedSubscriptions: [SubscriptionTypes.fullyPromoted]}) ? [{label: 'Credits', value: 'credits'}] : [],
              ]
            }
          />
        </Grid>
        <Grid item xs={12}>
          <TabPanel value='details' sx={{p: 0, pt: 3}}><JobDetails job={job} updateJobBoard={updateJobBoard} /></TabPanel>
          <TabPanel value='purchase-orders' sx={{p: 0, pt: 3}}><JobPurchaseOrders job={job} /></TabPanel>
          <TabPanel value='proofs' sx={{p: 0, pt: 3}}><JobProofs updateJobBoard={updateJobBoard} /></TabPanel>
          <TabPanel value='files' sx={{p: 0, pt: 3}}><Files
            customerId={job?.customerId}
            getEntityIds={[job?._id]}
            setEntityIds={[job?._id]}
            isProof={false}
            isWorkable={true}
            createTaskState={{jobIds: [job?._id], jobs: [job]}}
            showCustomerBtn={true}
            showUploadFileBtn={true}
            entityTitle='Job'
          /></TabPanel>
          <TabPanel value='comments' sx={{p: 0, pt: 3}}><Comments
            channelId={job?.linkComments ? `quote-${job?.quoteId}` : `job-${job?._id}`}
            meta={{
              entityId: job?._id,
              entityType: 'job',
              entityTypeName: 'Job',
              entityNumber: job?.number,
              customerName: job?.customer?.name,
            }}
          /></TabPanel>
          <TabPanel value='tasks' sx={{p: 0, pt: 3}}><JobTasks job={job}/></TabPanel>
          <TabPanel value='activity' sx={{p: 0, pt: 3}}><JobActivityTab job={job}/></TabPanel>
          <TabPanel value='shipping' sx={{p: 0, pt: 3}}><JobShipping job={job}/></TabPanel>
          <TabPanel value='credits' sx={{p: 0, pt: 3}}><JobCredits job={job}/></TabPanel>
        </Grid>
      </Grid>
    </TabContext>

  );
};
